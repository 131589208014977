import * as React from "react"
import content from "../content.json"
import AboutMe from "../components/AboutMe"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"

const SobreMi = () => (
  <Layout>
    <Seo {...content.pages.aboutMe.seo} />
    <AboutMe />
  </Layout>
)

export default SobreMi
