import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import dot from "../images/shapes/dot.svg"

import pinkT from "../images/shapes/pink-triangle.svg"
import content from "../content.json"
import ReadMore from "./ReadMore"
import Testimonial from '../components/Testimonial';

const Section = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 104px;
    width: 173px;
    top: 56px;
    left: -70px;
    z-index: -1;
  }

  @media only screen and (max-width: 1100px) {
    ::before {
      left: -121px;
    }
  }

  @media only screen and (max-width: 992px) {
    ::before {
      left: -44px;
      height: 49px;
      width: 90px;
      background-size: cover;
    }
  }
`

const Container = styled.div`
  margin: 48px auto;
  max-width: 1024px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
  }
`

const MainText = styled.div`
  max-width: 280px;
  margin: 0 auto;

  @media (min-width: 600px) {
    max-width: 440px;
  }

  @media (min-width: 768px) {
    max-width: 60%;
    padding-right: 48px;
  }
`

const Img = styled.div`
  position: relative;
  margin: 32px auto;
  max-width: 240px;
  width: 51%;
  text-align: center;

  ::before {
    content: "";
    position: absolute;
    background: url(${pinkT});
    background-repeat: no-repeat;
    z-index: -1;
    bottom: 3%;
    right: 10%;
    width: 106%;
    height: 102.5%;
    background-size: cover;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`

const Dots = styled.div`
  z-index: 10;

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 50.4px;
    width: 94.2px;
    top: 30%;
    right: -7%;
    z-index: -1;
    background-size: cover;
  }

  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 23.88px;
    width: 20.18px;
    top: 15%;
    right: 12%;
    z-index: -1;
    background-size: cover;
  }

  @media (min-width: 600px) {
    ::before {
      width: 115.85px;
      height: 62.02px;
      right: -10%;
    }

    ::after {
      width: 30.46px;
      height: 36.05px;
    }
  }
`


export default function AboutMe() {
  return (
    <Section>
      <Container>
        <MainText>
          <h2>{content.pages.aboutMe.heading}</h2>
          <ReadMore>
            {content.pages.aboutMe.text.map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </ReadMore>
        </MainText>
        <div>
          <Img>
            <Dots>
              <StaticImage
                src="../images/marialy/marialy1.png"
                alt="marialy"
                placeholder="blurred"
              />
            </Dots>
          </Img>

          <Testimonial>
            <p>{content.pages.aboutMe.testimonial.text}</p>
            <p>- {content.pages.aboutMe.testimonial.person}.</p>
          </Testimonial>
        </div>
      </Container>
    </Section>
  )
}
